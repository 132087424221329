<template>
    <div class="upload-area">
        <div class="upload-source">
            <span class="t1">数据来源</span>
            <span class="file-name">{{ fileName }} </span>
            <span v-if="total" class="progress"> ({{ finished }}/{{ total }})</span>
            <button @click="delTmpData" v-if="fileName" class="close-btn"></button>
        </div>
        <div>
            <a-button class="t2" @click="openExcel"> 模板下载</a-button>
            <a-upload
                    :show-upload-list="false"
                    :action="action"
                    @change="handleChange"
                    :before-upload="beforeUpload"
            >
                <a-button> 上传Excel</a-button>
            </a-upload>
        </div>
    </div>
</template>

<script>
import api from "../../../../repo/api";
import auth from "../../../../auth";

export default {
    name: "ExcelUploader",
    data() {
        let appId = localStorage['mini_app_id'];

        return {
            fileName: '',
            action: api.colUrl('/ast-shop/upload-excel?token=' + auth.getToken() + '&_mini_app_id=' + appId)
        }

    },
    props: {
        total: Number,
        finished: Number
    },
    mounted() {
        this.getHistory()
    },
    methods: {
        delTmpData() {
            this.$confirm({
                title: '确定要清理所有导入数据吗?',
                onOk: () => {
                    this.$loading.show()

                    api.post('/ast-shop/clean-tmp', {}, () => {

                        this.$loading.hide()

                        this.fileName = ''

                        this.$message.success('清理成功');
                    })
                },
            });
        },
        openExcel() {
            window.open('https://static.ycsd.work/bang/template_v2.xlsx')
        },
        getHistory() {
            api.get('/ast-shop/history-excel-name', (data) => {
                this.fileName = data.data.name;
            })
        },
        handleChange(info) {

            if (info.file.status === 'uploading') {
                this.$loading.show()
                return;
            }

            if (info.file.status === 'done') {

                let response = info.file.response;

                if (response.code !== 0) {
                    this.$loading.hide()
                    return this.$message.error(response.msg)
                }

                let url = ''

                if (typeof this.done === 'function') {
                    url = this.done(response.data)
                } else {
                    url = response.data
                    this.fileName = url
                }

                this.$loading.hide()

            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

            if (!isJpgOrPng) {
                this.$message.error('你只能上传xlsx文件!');
            }
            const isLt1M = file.size / 1024 / 1024 < 1;
            if (!isLt1M) {
                this.$message.error('文件必须小于1M!');
            }
            return isJpgOrPng && isLt1M;
        },
    }
}
</script>

<style lang="less" scoped>
.upload-source {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    margin-bottom: 24px;
    display: flex;
    align-content: center;
    align-items: center;

    .t1 {
        margin-right: 24px;
    }

    .file-name {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
    }

    .close-btn {
        width: 14px;
        height: 14px;
        margin-left: 24px;
        border: none;
        cursor: pointer;
        background: url("/assets/icons/close-btn-black.png");
        background-size: 14px;
    }
}

.t2 {
    margin-right: 16px;
}

.progress {
    margin-left: 10px;
    color: red;
}
</style>